import logo from './logo.svg';
import './App.css';
import cards from './Cards/cards.json';
import orderingInfo from './OrderingInfo/orderingInfo.json';

import * as React from 'react';
import { Button, View, Text, StyleSheet } from 'react-native';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import { getUA } from 'react-device-detect'

ReactGA.initialize('G-N6S48D1JN3')

function Home() {
  ReactGA.pageview(window.location.pathname)
  return (
    <div className="App">
      {/* <div data-banner-id="1422060"></div> */}
      <header className="App-header">
        <div>
        <Helmet>
          <title>Playboy Trading Cards - Sexy Naked Playboy Girls</title>
          <meta name='description' content= "Buy Playboy Trading Cards.  Selling trading cards featuring sexy nude Playboy Models. The hottest ladies naked, showing boobs, bums and pussies all on collectable trading cards." />
          {/* <script data-cfasync="false" src="//dcbbwymp1bhlf.cloudfront.net/?wbbcd=1107115"></script> */}
          <link rel='canonical' href={window.location.href}></link>
        </Helmet>
        <h1 className="Main-h1"><Text style={styles.titleText}>Playboy Trading Cards</Text></h1>
        <h2 className="Main-h2"><Text style={styles.h2Text}>Sexy Playboy Models, naked on collectable trading cards.{'\n'}
          Choose from your favorite Playboy centerfolds, bunnies and models.</Text></h2>
        {/* <p></p> */}
        <Text style={styles.bodyText} className="Home-body">
          This site lists the Playboy Trading Card singles I have for sale.{'\n'}
          The site has them organised by set.{'\n'}
          Each set includes images of the card, along with price and stock of that card.{'\n'}
          It takes a while to get through the sets, organise, photograph and list them. So the contents should grow over time.
        </Text>
        <p></p>
        {
        cards.sets.map((element, index) => {
          return(
            <div>
              <a 
              style={styles.linkText}
              className="Site_link"
              href={ window.location.href + 'set/' + element.display_name }>
              {element.display_name}
              </a>
            <p></p>
            </div>
          )
          })
        }
        </div>

      <div data-nat='1424488'></div>
      </header>
      <div className='Bottom-whitespace '></div>
    </div>
  );
}

const Set = () => {
  ReactGA.pageview(window.location.pathname)
  let params = useParams()
  const setName = params.set 

  function getSet() {
    return cards.sets.find(element => {
      return element.display_name == setName
    })
  }

  function getCards() { 
    return getSet().cards
  }

  return (
    <div className="Set">
      <Helmet>
          <title>{ getSet().display_name }</title>
          <meta name='description' content= { "Buy Playboy Trading Cards from the " + getSet().display_name + " set. Selling trading cards featuring sexy nude Playboy Models. The hottest ladies naked, showing boobs, bums and pussies all on collectable trading cards." } />
          {/* <script data-cfasync="false" src="//dcbbwymp1bhlf.cloudfront.net/?wbbcd=1107115"></script> */}
          <link rel='canonical' href={window.location.href}></link>
        </Helmet>
        <div data-nat='1424486'></div>
      <h1><Text style={styles.titleText}>{setName}</Text></h1>
      <h2 style={styles.bodyText}> List of Playboy trading cards from the { getSet().display_name } set </h2>
      <table border="1" frame="void" rules="rows">
      <tbody className='Set-table'>
      {getCards().map((element, index) => {
      return(
        [
        <tr>
          <td>
            { element.number }
            <p></p>
            <a
              style={styles.linkText}
              className="Site_link"
              href= { window.location.href + '/card/' + element.name + ' (' + element.number + ')' }
              >{element.name}</a></td>
          <td>
           £{element.price}
            <p></p>
            { element.quantity } Available
          </td>
          <td onClick={ () => 
                window.location.href = window.location.href + '/card/' + element.name + ' (' + element.number + ')'
              }>
            <img alt={element.name + ' front'} className='Preview-image' loading="lazy" src={window.location.origin + getSet().image_folder + 'Thumbnails/' + getSet().image_naming_prefix + element.number + "-front.JPG"} />
            <img alt={element.name + ' back'} className='Preview-image' loading="lazy" src={window.location.origin + getSet().image_folder + 'Thumbnails/' + getSet().image_naming_prefix + element.number + "-back.JPG"} />
          </td>
          </tr>,
          <tr>
            { (index + 1 === 30) && <td COLSPAN="3"><div data-nat='1424490'></div></td> }
            { (index + 1 === 60) && <td COLSPAN="3"><div data-nat='1424494'></div></td> }
            { (index + 1 === 90) && <td COLSPAN="3"><div data-nat='1424495'></div></td> }
          </tr>
          ]
        )
      })}
      </tbody>
      </table>
      <div className='Bottom-whitespace '></div>
    </div>
  )
}

const Card = () => {
  ReactGA.pageview(window.location.pathname)
  let params = useParams()
  const setName = params.set 
  const card = params.card

  function getSet() {
    return cards.sets.find(element => {
      return element.display_name == setName
    })
  }

  function getCard() { 
    const regex = /\(([^)]+)\)/
    const match = card.match(regex)
    const cardNumber = match[1]
    return getSet().cards.find(element => {
      return element.number == cardNumber
    })
  }

  return (
    <div  className="Set">
      <Helmet>
          <title>{getCard().number} - {getCard().name}</title>
          <meta name='description' content= { "Buy Playboy Trading Card featuring " + getCard().name + ". Tags: " + getCard().tags.join(', ') + '. Selling trading cards featuring sexy nude Playboy Models. The hottest ladies naked, showing boobs, bums and pussies all on collectable trading cards.' } />
          {/* <script data-cfasync="false" src="//dcbbwymp1bhlf.cloudfront.net/?wbbcd=1107115"></script> */}
          <link rel='canonical' href={window.location.href}></link>

          <script type="application/ld+json">
  { JSON.stringify({
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": getCard().number + ' - ' + getCard().name,
    "image": [
      window.location.origin + getSet().image_folder + "1x1/" + getSet().image_naming_prefix + getCard().number + "-front.JPG",
      window.location.origin + getSet().image_folder + "4x3/" + getSet().image_naming_prefix + getCard().number + "-front.JPG",
      window.location.origin + getSet().image_folder + "16x9/" + getSet().image_naming_prefix + getCard().number + "-front.JPG"
    ],
    "description": "Playboy Trading Card featuring " + getCard().name + ". Tags: " + getCard().tags.join(', '),
    "offers": {
      "@type": "Offer",
      "url": window.location.href,
      "priceCurrency": "GBP",
      "price": getCard().price
    }

  } )}
  </script>
        </Helmet>

        <div data-nat='1424486'></div>
      <h1><p>{getCard().number} - {getCard().name}</p></h1>
      <h2  style={styles.bodyText}> Playboy trading card from the { getSet().display_name } set, featuring { getCard().name } </h2>
      <p>£{getCard().price}</p>
      <p>{ getCard().quantity } Available</p>
      <p><a href={ window.location.href.substring(0, window.location.href.lastIndexOf('/') - 5) }>{ getSet().display_name }</a></p>
      <img alt={getCard().name + ' playboy card. ' + getCard().tags.join(', ')} className='Full-image' loading="lazy" src={window.location.origin + getSet().image_folder + getSet().image_naming_prefix + getCard().number + "-front.JPG"} />
      <img alt={getCard().name + ' playboy card. ' + getCard().tags.join(', ') + ' back'} className='Full-image' loading="lazy" src={window.location.origin + getSet().image_folder + getSet().image_naming_prefix + getCard().number + "-back.JPG"} />

      <p></p>
      <p>Tags: { getCard().tags.map((element, index) => { return(<p>{ element }</p>) }) }</p>
      <div className='Bottom-whitespace '></div>
      </div>
  )
}

const OrderingInfo = () => {
  ReactGA.pageview(window.location.pathname)
  return (
    <div  className="Ordering-info">

<div data-nat='1424486'></div>
      <Helmet>
        <link rel='canonical' href={window.location.href}></link>
      </Helmet>
      {orderingInfo.info.map((element, index) => {
          return(
            <div>
              <Text style={styles.headerText}>
                { element.title }
              </Text>
              <p></p>
              <Text style={styles.bodyText}>
                { element.description }
              </Text>
            <p></p>
            <hr></hr>
            </div>
          )
      })}
     <div className='Bottom-whitespace '></div>
    </div>
  )
}

function App() {
  let isBot = (getUA.toLowerCase().includes('bot') || getUA.toLowerCase().includes('inspectiontool') || getUA.toLowerCase().includes('bing') || getUA.toLowerCase().includes('headlesschrome'))
  console.log(getUA)
  let isOldEnough = window.sessionStorage.getItem("passedAgeVerification");
  if (isOldEnough || isBot) {
    return (
      <Router>
        <main className='App'>
          <a  style={styles.navLinkText} className='Navigation-link' href="/">Home</a>
          <a  style={styles.navLinkText} className='Navigation-link' href="/ordering-info">How to Order/FAQs</a>
        <Routes>
          <Route path='/' Component={Home}></Route>
          <Route path='/set/:set' Component={Set}></Route>
          <Route path='/set/:set/card/:card' Component={Card}></Route>
          <Route path='/ordering-info' Component={OrderingInfo}></Route>
        </Routes>
        </main>
      </Router>

    );
  } else {
    function setPassed () {
      window.sessionStorage.setItem("passedAgeVerification", true);
      window.location.href = window.location.href
    }
    return (
      <div className='Age-verification'>
        <p>
        <Text style={styles.bodyText}>
        This site contains adult material that may not be suitable for all ages. Please confirm you are over 18 to enter or go back.</Text>
        </p>
      <a 
      style={styles.linkText}
      className="Site_link"
      onClick={ () => 
        setPassed()
      }>
      I am over 18
      </a>
      <p></p>
      </div>
    );
  }
}

// function App() {
//   return(
//     <div>
//       <Router>
//         <main className='App'>
//         <Routes>
//           <Route path='/' Component={SiteMap}></Route>
//         </Routes>
//         </main>
//       </Router>
//     </div>
//   )
// }

const styles = StyleSheet.create({
  bodyText: {
    fontSize: 15,
    color: '#4a4749',
    textAlign: 'center',
  },
  h2Text: {
    fontSize: 18,
    color: '#4a4749',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  headerText: {
    fontSize: 17,
    fontWeight: 800,
    color: '#4a4749',
    textAlign: 'center',
  },
  titleText: {
    fontSize: 40,
    color: '#4a4749',
    fontWeight: 'bold',
  },
  linkText: {
    fontSize: 25,
    color: '#6a76f7',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  navLinkText: {
    fontSize: 25
  },
  linkTableEntryText: {
    fontSize: 25,
    color: '#6a76f7',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word'
  },
});

export default App;

function SiteMap() {
  return (
    <div className="App">
      &lt;?xml version="1.0" encoding="UTF-8"?&gt;
      &lt;urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:image="http://www.google.com/schemas/sitemap-image/1.1" &gt;
        &lt;url&gt;
        &lt;loc&gt;{ 'https://tradingcardspb.com' }&lt;/loc&gt;
        &lt;/url&gt;
        {
        cards.sets.map((element, index) => {
          return(
            <div>
              &lt;url&gt;
              &lt;loc&gt;{ encodeRFC3986URI('https://tradingcardspb.com/set/' + element.display_name) }&lt;/loc&gt;
              &lt;/url&gt;

              {element.cards.map((card, index) => {
                return(
                  <div>
                    &lt;url&gt;
                    &lt;loc&gt;{ encodeRFC3986URI('https://tradingcardspb.com/set/' + element.display_name + '/card/' + card.name + ' (' + card.number + ')') }&lt;/loc&gt;
                      &lt;image:image&gt;
                      &lt;image:loc&gt;{ encodeRFC3986URI('https://tradingcardspb.com' + element.image_folder + element.image_naming_prefix + card.number + '-front.JPG') }&lt;/image:loc&gt;
                      &lt;/image:image&gt;
                    &lt;/url&gt;
                  </div>
                )
              })}
            </div>
          )
          })
        }
      &lt;/urlset&gt;
    </div>
  );
}

function encodeRFC3986URI(str) {
  return encodeURI(str)
    .replace(/%5B/g, "[")
    .replace(/%5D/g, "]")
    .replace(
      /[!'()*]/g,
      (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`,
    );
}
